export const cardTypes = {
  text: { name: "Text" },
  image: { name: "Image" },
  video: { name: "Video" },
  prayer: { name: "Prayer" },
  quote: { name: "Quote" },
  word: { name: "Word" },
  scripture: { name: "Bible" },
  intro: { name: "Series Intro" },
  backgroundImage: { name: "Text on Image" },
  socialMedia: { name: "Social Media C2A" },
};
