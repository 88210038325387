import React, { useEffect, useState } from "react";

import { Button, Form, Input, Select } from "antd";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { db } from "../../firebase";
import "draft-js/dist/Draft.css";
import "./FormStyles.css";

const QuoteCardForm = ({ initialValues, id, dateKey }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  useEffect(() => {
    setUnsavedChanges(true);
  }, [formValues]);

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 4,
      offset: 16,
    },
  };

  function onFormInputsChange(newValue) {
    setFormValues({ ...formValues, ...newValue });
  }

  function saveContent() {
    if (!unsavedChanges) {
      return;
    }
    setSaving(true);
    db.collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .update({
        ...formValues,
      })
      .then(() => {
        setUnsavedChanges(false);
        setSaving(false);
      });
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      onValuesChange={onFormInputsChange}
      initialValues={formValues}
    >
      <Form.Item label="Quote" name="quote">
        <Input.TextArea placeholder="quote" autoSize={{ minRows: 7 }} />
      </Form.Item>
      <Form.Item label="Author" name="author">
        <Input placeholder="author" />
      </Form.Item>
      <Form.Item label="BG Start" name="backgroundStart">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item label="BG End" name="backgroundEnd">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button
          type="primary"
          onClick={saveContent}
          disabled={!unsavedChanges}
          loading={saving}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default QuoteCardForm;
