import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBkmTkHeHWV3p0CnRqRaHtZa_oEp-_Fcbg",
  authDomain: "good-ground-fa024.firebaseapp.com",
  databaseURL: "https://good-ground-fa024.firebaseio.com",
  projectId: "good-ground-fa024",
  storageBucket: "good-ground-fa024.appspot.com",
  messagingSenderId: "793811584782",
  appId: "1:793811584782:web:e7db72eb730724996cb95f",
  measurementId: "G-L6R8W2L03B",
};

firebase.initializeApp(firebaseConfig);
const provider = new firebase.auth.GoogleAuthProvider();

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};
export const signOut = () => {
  auth.signOut();
};
