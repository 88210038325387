import { storage } from "../firebase";

export const uploadImageToFirebaseStorage = async ({
  onError,
  onSuccess,
  file,
}) => {
  console.log(file);
  const storageRef = await storage.ref();
  const imageName = `${file.uid}.${file.name.split(".").pop()}`;
  const imgFile = storageRef.child(`images/${imageName}`);
  let imageUrl = "";
  try {
    const image = await imgFile.put(file);
    imageUrl = await imgFile.getDownloadURL();
    onSuccess(null, image);
  } catch (e) {
    onError(e);
  }
  return imageUrl;
};

export const verifyImageType = (file) => {
  if (file.type === "image/png" || file.type === "image/jpeg") {
    return true;
  } else {
    return false;
  }
};
