import React, { useEffect, useState } from "react";

import { Button, Form, Input, Select } from "antd";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { db } from "../../firebase";
import "draft-js/dist/Draft.css";
import "./FormStyles.css";

const ScriptureCardForm = ({ initialValues, id, dateKey }) => {
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [form] = Form.useForm();
  const [editorState, setEditorState] = React.useState(() => {
    if (initialValues.scripture) {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(initialValues.scripture))
      );
    } else {
      return EditorState.createEmpty();
    }
  });
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  useEffect(() => {
    setUnsavedChanges(true);
  }, [formValues]);

  function saveContent() {
    if (!unsavedChanges) {
      return;
    }
    setSaving(true);
    db.collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .update({
        ...formValues,
      })
      .then(() => {
        setUnsavedChanges(false);
        setSaving(false);
      });
  }

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 4,
      offset: 16,
    },
  };

  function onFormInputsChange(newValue) {
    setFormValues({ ...formValues, ...newValue });
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      onValuesChange={onFormInputsChange}
      initialValues={formValues}
    >
      <Form.Item>
        <Editor
          toolbar={{
            options: ["inline", "list", "history"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          editorState={editorState}
          toolbarClassName="todo"
          wrapperClassName="editor-wrapper"
          editorClassName="todo"
          onEditorStateChange={setEditorState}
          onChange={(text) =>
            onFormInputsChange({ scripture: JSON.stringify(text) })
          }
        />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button
          type="primary"
          onClick={saveContent}
          disabled={!unsavedChanges}
          loading={saving}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ScriptureCardForm;
