import React, { useEffect, useState } from "react";

import { Button, Form, Input } from "antd";
import IconSelect from "./SharedInputs/IconSelect";

import { db } from "../../firebase";
import "./FormStyles.css";

const SocialMediaCardForm = ({ initialValues, id, dateKey }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  useEffect(() => {
    setUnsavedChanges(true);
  }, [formValues]);

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 4,
      offset: 16,
    },
  };

  function onFormInputsChange(newValue) {
    setFormValues({ ...formValues, ...newValue });
  }

  function saveContent() {
    if (!unsavedChanges) {
      return;
    }
    setSaving(true);
    db.collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .update({
        ...formValues,
      })
      .then(() => {
        setUnsavedChanges(false);
        setSaving(false);
      });
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      onValuesChange={onFormInputsChange}
      initialValues={formValues}
    >
      <IconSelect />

      <Form.Item label="Title" name="title">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item label="Body Text" name="text">
        <Input.TextArea placeholder="" autoSize={{ minRows: 3 }} />
      </Form.Item>

      <Form.Item {...buttonItemLayout}>
        <Button
          type="primary"
          onClick={saveContent}
          disabled={!unsavedChanges}
          loading={saving}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SocialMediaCardForm;
