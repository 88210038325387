import React from "react";

import { Form, Select } from "antd";

const IconSelect = () => {
  return (
    <Form.Item label="Icon" name="icon">
      <Select>
        <Select.Option value="bible">Bible</Select.Option>
        <Select.Option value="brush">Brush</Select.Option>
        <Select.Option value="music">Music</Select.Option>
        <Select.Option value="leaf">Leaf</Select.Option>
        <Select.Option value="book">Book</Select.Option>
        <Select.Option value="pen">Pen</Select.Option>
        <Select.Option value="ear">Ear</Select.Option>
        <Select.Option value="eye">Eye</Select.Option>
        <Select.Option value="glasses">Glasses</Select.Option>
        <Select.Option value="wave">Wave</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default IconSelect;
