import React from 'react'
import { Layout, Button } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'
import { signInWithGoogle } from '../firebase'
import logo from '../Assets/logo.png'

const { Header, Content } = Layout

const SignIn = () => {
  return (
    <Layout className="layout">
      <Header style={{backgroundColor: '#203C3A'}}>
        <img src={logo} alt="Good Ground logo" className="logo" />
      </Header>
      <Content style={{ padding: '50px' }}>
        <div className="site-layout-content">
          <h1 className="title">Good Ground Writers Room</h1>
          <Button
            className="login-button"
            type="primary"
            shape="round"
            icon={<GoogleOutlined />}
            size="large"
            onClick={signInWithGoogle}
          >
            Log in with Google
          </Button>
        </div>
      </Content>
    </Layout>
  )
}
export default SignIn
