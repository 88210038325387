import { db } from "../firebase";

export const loadCodes = () => {
  return db
    .collection("SubscriptionCodes")
    .get()
    .then((snapshot) => {
      const codes = [];
      snapshot.docs.forEach((code) => {
        codes.push({
          ...code.data(),
          code: code.id,
        });
      });
      return codes;
    });
};

export const generateCodes = async (numberOfCodesToGenerate, type, tag) => {
  let codesGenerated = [];

  while (codesGenerated.length < numberOfCodesToGenerate) {
    let codeToGenerate = Math.floor(Math.random() * 9000000000).toString(); // generate random 10 digit number starting with non-zero number
    codeToGenerate = codeToGenerate.padStart(10, "0");
    console.log(codeToGenerate);
    const codeLookupResult = await db
      .collection("SubscriptionCodes")
      .doc(codeToGenerate)
      .get()
      .then((snapshot) => {
        return snapshot.data();
      });
    console.log(codeLookupResult);
    if (codeLookupResult === undefined) {
      const codeGenerationResult = await generateCode(
        codeToGenerate,
        type,
        tag
      );
      codesGenerated.push(codeGenerationResult);
    }
  }

  return codesGenerated;
};

const generateCode = async (code, type, tag) => {
  await db.collection("SubscriptionCodes").doc(code).set({
    type: type,
    tag: tag,
    created: new Date(),
  });
  return code;
};
