import React, { useReducer, useState } from "react";
import { Menu } from "antd";

import { cardTypes } from "../Constants/CardTypes";

const NewItemMenu = ({ onClick, itemSection }) => {
  const cardTypeKeys = Object.keys(cardTypes) || [];
  return (
    <Menu>
      {cardTypeKeys.map((key) => {
        return (
          <Menu.Item
            key={key}
            onClick={({ key }) =>
              onClick({ cardType: key, itemSection: itemSection })
            }
          >
            <p>{cardTypes[key].name}</p>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default NewItemMenu;
