import React, { useState } from "react";
import {
  Layout,
  Menu,
  Button,
  Calendar,
  Modal,
  Dropdown,
  DatePicker,
} from "antd";
import { LoadingOutlined, EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";

import { deleteDevotion, moveDevotion } from "../Utils/API.js";
import { db } from "../firebase";
import DevotionEditor from "./DevotionEditor";

import "./CalendarScreen.css";
import "antd/dist/antd.css";

const CalendarScreen = () => {
  const [currentDevotion, setCurrentDevotion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const { Content } = Layout;
  const dateFormat = "dddd MMMM Do YYYY";

  const onSelectDate = async (date) => {
    const dateKey = date.format("YYYY-MM-DD");
    setLoading(true);
    setModalVisible(true);
    const curerntDevotionItems = await db
      .collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .get()
      .then((snapshot) => {
        const devotItems = [];
        snapshot.docs.forEach((devoItem) => {
          devotItems.push({
            ...devoItem.data(),
            id: devoItem.id,
          });
        });
        return devotItems.sort((a, b) => a.order - b.order);
      });
    const currentNotificationsText = await db
      .collection("Devotions")
      .doc(dateKey)
      .collection("notifications")
      .get()
      .then((snapshot) => {
        const notificationItems = {};
        snapshot.docs.forEach((notificationItem) => {
          const notificationItemData = notificationItem.data();
          console.log(notificationItemData);
          notificationItems[notificationItem.id] = notificationItemData.text;
        });
        return notificationItems;
      });

    setCurrentDevotion({
      dateKey,
      devotionItems: curerntDevotionItems,
      notificationsText: currentNotificationsText,
    });
    setLoading(false);
    setShouldRenderModal(true);
  };

  const changeDevotionDate = (dateKey, newDate) => {
    if (dateKey) {
      //TODO: show some loading here.
      moveDevotion(dateKey, newDate.format("YYYY-MM-DD"));
      // setModalVisible(false);
    }
  };

  const removeDevotionDay = (dateKey) => {
    if (dateKey) {
      deleteDevotion(dateKey);
      //TODO: show some loading here.
      setModalVisible(false);
    }
  };

  const renderCalendarCell = (date) => {
    // const dateString = date.format("YYYY-MM-DD");
    // console.log(dateString);
  };

  return (
    <>
      <Layout className="calendar-screen">
        <Content style={{ padding: "20px 50px" }}>
          <Calendar
            style={{ height: "100%" }}
            dateCellRender={renderCalendarCell}
            fullscreen={true}
            onSelect={onSelectDate}
            onPanelChange={(date) => console.log("PANEL CHANGE", date)}
          />
        </Content>
      </Layout>

      {shouldRenderModal && currentDevotion && (
        <Modal
          closable={false}
          width={800}
          title={
            <>
              <DatePicker
                bordered={false}
                suffixIcon={null}
                format={dateFormat}
                defaultValue={moment(
                  currentDevotion && currentDevotion.dateKey
                )}
                onChange={(date) =>
                  changeDevotionDate(currentDevotion.dateKey, date)
                }
              />
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() => removeDevotionDay(currentDevotion.dateKey)}
                    >
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomCenter"
              >
                <Button
                  size="small"
                  style={{ float: "right" }}
                  type={"text"}
                  onClick={(e) => e.preventDefault()}
                  icon={<EllipsisOutlined style={{ fontSize: 24 }} />}
                />
              </Dropdown>
            </>
          }
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          afterClose={() => setShouldRenderModal(false)}
        >
          {loading && <LoadingOutlined style={{ fontSize: 24 }} spin />}
          {!loading && (
            <DevotionEditor
              devotionItems={currentDevotion.devotionItems}
              notificationsText={currentDevotion.notificationsText}
              dateKey={currentDevotion.dateKey}
            />
          )}
        </Modal>
      )}
    </>
  );
};
export default CalendarScreen;
