import React, { useEffect, useState } from "react";

import { Button, Form, Input, Select } from "antd";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { db } from "../../firebase";
import "draft-js/dist/Draft.css";
import "./FormStyles.css";

const PrayerCardForm = ({ initialValues, id, dateKey }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editorState1, setEditorState1] = React.useState(() => {
    if (initialValues.instructions) {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(initialValues.instructions))
      );
    } else {
      return EditorState.createEmpty();
    }
  });
  const [editorState2, setEditorState2] = React.useState(() => {
    if (initialValues.prayer) {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(initialValues.prayer))
      );
    } else {
      return EditorState.createEmpty();
    }
  });
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  useEffect(() => {
    setUnsavedChanges(true);
  }, [formValues]);

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 4,
      offset: 16,
    },
  };

  function onFormInputsChange(newValue) {
    setFormValues({ ...formValues, ...newValue });
  }

  function saveContent() {
    if (!unsavedChanges) {
      return;
    }
    setSaving(true);
    db.collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .update({
        ...formValues,
      })
      .then(() => {
        setUnsavedChanges(false);
        setSaving(false);
      });
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      onValuesChange={onFormInputsChange}
      initialValues={formValues}
    >
      <Form.Item>
        <Editor
          toolbar={{
            options: ["inline", "list", "history"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          editorState={editorState1}
          toolbarClassName="todo"
          wrapperClassName="editor-wrapper"
          editorClassName="todo"
          onEditorStateChange={setEditorState1}
          onChange={(text) => {
            if (text.blocks.length === 1 && text.blocks[0].text === "") {
              onFormInputsChange({ instructions: null });
            } else {
              onFormInputsChange({ instructions: JSON.stringify(text) });
            }
          }}
        />
      </Form.Item>
      <Form.Item>
        <Editor
          toolbar={{
            options: ["inline", "list", "history"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          editorState={editorState2}
          toolbarClassName="todo"
          wrapperClassName="editor-wrapper"
          editorClassName="todo"
          onEditorStateChange={setEditorState2}
          onChange={(text) => {
            if (text.blocks.length === 1 && text.blocks[0].text === "") {
              onFormInputsChange({ prayer: null });
            } else {
              onFormInputsChange({ prayer: JSON.stringify(text) });
            }
          }}
        />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button
          type="primary"
          onClick={saveContent}
          disabled={!unsavedChanges}
          loading={saving}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PrayerCardForm;
