import React, { useContext, useState } from "react";
import SignIn from "./SignIn";
import CalendarScreen from "./CalendarScreen";
import SubscriptionCodesScreen from "./SubscriptionCodesScreen";
import { UserContext } from "../Providers/UserProvider";

import { Layout, Menu, Button } from "antd";
import {
  CalendarOutlined,
  UserOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { signOut } from "../firebase";

import "./Application.css";
import "antd/dist/antd.css";

import logo from "../Assets/logo.png";

function Application() {
  const { user, userLoading } = useContext(UserContext);
  const [activeMenuItem, setActiveMenuItem] = useState("calendar");

  const { Header } = Layout;

  if (userLoading) {
    return null;
  }

  if (!user) {
    return <SignIn />;
  }

  return (
    <div className="gg-dashboard">
      <Header style={{ backgroundColor: "#203C3A" }}>
        <img src={logo} alt="Good Ground logo" className="logo" />
        <Menu
          theme={"dark"}
          style={{ backgroundColor: "#203C3A" }}
          mode="horizontal"
          onClick={(e) => {
            console.log(e);
            setActiveMenuItem(e.key);
          }}
          selectedKeys={[activeMenuItem]}
        >
          <Menu.Item key="calendar" icon={<CalendarOutlined />}>
            Content Calendar
          </Menu.Item>
          <Menu.Item key="codes" icon={<GiftOutlined />}>
            Subscription Codes
          </Menu.Item>
          <Menu.Item key="users" icon={<UserOutlined />}>
            Users
          </Menu.Item>
          <Button type="primary" className="logout-button" onClick={signOut}>
            Log out
          </Button>
        </Menu>
      </Header>
      {activeMenuItem === "calendar" && <CalendarScreen />}
      {activeMenuItem === "codes" && <SubscriptionCodesScreen />}
      {activeMenuItem === "users" && <> </>}
    </div>
  );
}
export default Application;
