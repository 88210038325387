import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Collapse,
  Dropdown,
  Popconfirm,
  Typography,
  Divider,
  Input,
  Row,
  Col,
  Radio,
} from "antd";

import {
  PlusOutlined,
  UpOutlined,
  DownOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import {
  TextCardForm,
  PrayerCardForm,
  QuoteCardForm,
  ImageCardForm,
  WordOfDayCardForm,
  VideoCardForm,
  ScriptureCardForm,
  IntroCardForm,
  BackgroundImageCardForm,
  SocialMediaCardForm,
} from "./Forms";
import { db } from "../firebase";

import "./DevotionEditor.css";

import NewItemMenu from "./NewItemMenu";

const { Title, Text } = Typography;
const { TextArea } = Input;

const DevotionEditor = ({ devotionItems, notificationsText = {}, dateKey }) => {
  const [localDevotionItems, setLocalDevotionItems] = useState(
    devotionItems.sort((a, b) => a.order - b.order)
  );
  const [morningNotificationText, setMorningNotificationText] = useState(
    notificationsText.morning
  );
  const [eveningNotificationText, setEveningNotificationText] = useState(
    notificationsText.evening
  );
  const [
    morningNotificationSavingText,
    setMorningNotificationSavingText,
  ] = useState(null);
  const [
    eveningNotificationSavingText,
    setEveningNotificationSavingText,
  ] = useState(null);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { Panel } = Collapse;

  const deleteItem = async (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    await db
      .collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .delete();

    const localDevotionsClone = localDevotionItems;
    const deletedElement = localDevotionsClone.find(
      (element) => element.id === id
    );
    const deletedElementIndex = localDevotionsClone.indexOf(deletedElement);

    if (deletedElementIndex > -1) {
      localDevotionsClone.splice(deletedElementIndex, 1);
      setLocalDevotionItems(localDevotionsClone);
    }
    forceUpdate();
  };

  const createNewCard = async (newCard) => {
    const section1 = localDevotionItems.filter((item) => item.section === 1);
    const section2 = localDevotionItems.filter((item) => item.section === 2);

    const sectionlengths = [section1.length, section1.length + section2.length];

    const newDevotionItem = {
      type: newCard.cardType,
      order: sectionlengths[newCard.itemSection - 1] + 1,
      section: newCard.itemSection,
      isPremium: newCard.itemSection === 2,
    };

    // create a new entry and add it to local array.
    const newItem = await db
      .collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .add(newDevotionItem);

    setLocalDevotionItems([
      ...localDevotionItems,
      { ...newDevotionItem, id: newItem.id },
    ]);
  };

  const savePushNotification = (key, notificationText) => {
    if (key === "morning") {
      setMorningNotificationSavingText("Saving...");
    } else if (key === "evening") {
      setEveningNotificationSavingText("Saving...");
    }
    db.collection("Devotions")
      .doc(dateKey)
      .collection("notifications")
      .doc(key)
      .set({
        text: notificationText,
      })
      .then(() => {
        if (key === "morning") {
          setMorningNotificationSavingText("Saved notification successfully.");
        } else if (key === "evening") {
          setEveningNotificationSavingText("Saved notification successfully.");
        }
      });
  };
  const deletePushNotification = (key) => {
    db.collection("Devotions")
      .doc(dateKey)
      .collection("notifications")
      .doc(key)
      .delete()
      .then(() => {
        if (key === "morning") {
          setMorningNotificationSavingText(
            "Deleted notification successfully."
          );
          setMorningNotificationText(null);
        } else if (key === "evening") {
          setEveningNotificationSavingText(
            "Deleted notification successfully."
          );
          setEveningNotificationText(null);
        }
      });
  };

  const changeContentClass = (id, isPremium) => {
    db.collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .update({
        isPremium: isPremium,
      });
  };

  const changeContentOrder = (id, newOrderValue) => {
    db.collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .update({ order: parseInt(newOrderValue) || 0 });

    const devotionsCopy = [...localDevotionItems];
    const itemToChangeIndex = devotionsCopy.findIndex((item) => item.id === id);
    devotionsCopy[itemToChangeIndex].order = newOrderValue;
    devotionsCopy.sort((a, b) => a.order - b.order);
    setLocalDevotionItems(devotionsCopy);
    forceUpdate();
  };

  const PanelHeader = ({ id, order, title, isPremium, premiumLocked }) => {
    return (
      <div className={"devotion_editor__header"}>
        <div className="devotion_editor_title">{title}</div>

        <Radio.Group
          className="radio__container"
          defaultValue={isPremium ? "premium" : "free"}
          size="small"
          onChange={(e) => changeContentClass(id, e.target.value === "premium")}
        >
          <Radio.Button value="free" className="free" disabled={premiumLocked}>
            Free
          </Radio.Button>
          <Radio.Button value="premium" className="premium">
            Premium
          </Radio.Button>
        </Radio.Group>

        <div>
          <Input
            size="small"
            min={1}
            max={20}
            defaultValue={order}
            onChange={(e) => {
              const parsedValue = parseInt(e.target.value);
              if (isNaN(parsedValue)) return;
              changeContentOrder(id, parsedValue);
            }}
            onClick={(e) => e.stopPropagation()}
            controls={false}
          />
          &nbsp; &nbsp;
          <Popconfirm
            title="Are you sure？"
            onConfirm={(e) => deleteItem(e, id)}
            onCancel={(e) => e.stopPropagation()}
            icon={<QuestionCircleOutlined style={{ color: "#dc3545" }} />}
          >
            <Button
              size="small"
              onClick={(e) => e.stopPropagation()}
              icon={<DeleteOutlined style={{ color: "#dc3545" }} />}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const renderPanel = (devotionItem, isEvening = false) => {
    switch (devotionItem.type) {
      case "text":
        return (
          <Panel
            header={
              <PanelHeader
                id={devotionItem.id}
                order={devotionItem.order}
                isPremium={devotionItem.isPremium}
                premiumLocked={isEvening}
                title="✏️ &nbsp; Text"
              />
            }
            key={devotionItem.id}
          >
            <TextCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "prayer":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="🙏🏻 &nbsp; Prayer"
              />
            }
            key={devotionItem.id}
          >
            <PrayerCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "backgroundImage":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="🔠 &nbsp; Text on Image"
              />
            }
            key={devotionItem.id}
          >
            <BackgroundImageCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "quote":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="💬 &nbsp; Quote"
              />
            }
            key={devotionItem.id}
          >
            <QuoteCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "image":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="🌁 &nbsp; Image"
              />
            }
            key={devotionItem.id}
          >
            <ImageCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "word":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="📢 &nbsp; Word of the Day"
              />
            }
            key={devotionItem.id}
          >
            <WordOfDayCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "video":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="📼 &nbsp; Video"
              />
            }
            key={devotionItem.id}
          >
            <VideoCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "scripture":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="📔 &nbsp; Scripture"
              />
            }
            key={devotionItem.id}
          >
            <ScriptureCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "intro":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="🚩 &nbsp; Intro"
              />
            }
            key={devotionItem.id}
          >
            <IntroCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      case "socialMedia":
        return (
          <Panel
            header={
              <PanelHeader
                isPremium={devotionItem.isPremium}
                id={devotionItem.id}
                order={devotionItem.order}
                premiumLocked={isEvening}
                title="⚰️ &nbsp; SocialMedia"
              />
            }
            key={devotionItem.order}
          >
            <SocialMediaCardForm
              initialValues={devotionItem}
              id={devotionItem.id}
              dateKey={dateKey}
            />
          </Panel>
        );
      default:
        return null;
    }
  };

  const morningDevotionItems = localDevotionItems.filter(
    (devotionItem) => devotionItem.section === 1
  );
  const eveningDevotionItems = localDevotionItems.filter(
    (devotionItem) => devotionItem.section === 2
  );

  return (
    <>
      <Title level={4}>Morning Content</Title>
      <Input.Group size="large">
        <Row>
          <Col span={22}>
            <TextArea
              placeholder="Custom morning notification"
              value={morningNotificationText}
              maxLength={240}
              onChange={(e) => {
                setMorningNotificationText(e.target.value);
              }}
            />
          </Col>
          <Col span={2}>
            <div>
              &nbsp; &nbsp;
              <Button
                size="small"
                onClick={(e) =>
                  savePushNotification("morning", morningNotificationText)
                }
                icon={<SaveOutlined />}
              />
            </div>
            &nbsp; &nbsp;
            <Popconfirm
              title="Are you sure?"
              onConfirm={(e) => deletePushNotification("morning")}
              onCancel={(e) => e.stopPropagation()}
              icon={<QuestionCircleOutlined style={{ color: "#dc3545" }} />}
            >
              <Button
                size="small"
                onClick={(e) => e.stopPropagation()}
                icon={<DeleteOutlined style={{ color: "#dc3545" }} />}
              />
            </Popconfirm>
          </Col>
          {morningNotificationSavingText && (
            <Text italic>{morningNotificationSavingText}</Text>
          )}
        </Row>
      </Input.Group>
      &nbsp;
      <Collapse accordion>
        {morningDevotionItems.map((devotionItem, index) =>
          renderPanel(devotionItem, false)
        )}
        <Panel
          className="addNewButton"
          showArrow={false}
          collapsible={"disabled"}
          header={
            <Dropdown
              overlay={() => (
                <NewItemMenu itemSection={1} onClick={createNewCard} />
              )}
              placement="topCenter"
              arrow
            >
              <Button
                type="dashed"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={(e) => e.preventDefault()}
              />
            </Dropdown>
          }
          key="addNew"
        ></Panel>
      </Collapse>
      <Divider />
      <Title level={4}>Evening Content</Title>
      <Input.Group size="large">
        <Row>
          <Col span={22}>
            <TextArea
              placeholder="Custom evening notification"
              value={eveningNotificationText}
              maxLength={240}
              onChange={(e) => {
                setEveningNotificationText(e.target.value);
              }}
            />
          </Col>
          <Col span={2}>
            <div>
              &nbsp; &nbsp;
              <Button
                size="small"
                onClick={(e) =>
                  savePushNotification("evening", eveningNotificationText)
                }
                icon={<SaveOutlined />}
              />
            </div>
            &nbsp; &nbsp;
            <Popconfirm
              title="Are you sure?"
              onConfirm={(e) => deletePushNotification("evening")}
              onCancel={(e) => e.stopPropagation()}
              icon={<QuestionCircleOutlined style={{ color: "#dc3545" }} />}
            >
              <Button
                size="small"
                onClick={(e) => e.stopPropagation()}
                icon={<DeleteOutlined style={{ color: "#dc3545" }} />}
              />
            </Popconfirm>
          </Col>
          {eveningNotificationSavingText && (
            <Text italic>{eveningNotificationSavingText}</Text>
          )}
        </Row>
      </Input.Group>
      &nbsp;
      <Collapse accordion>
        {eveningDevotionItems.map((devotionItem, index) =>
          renderPanel(devotionItem, true)
        )}
        <Panel
          className="addNewButton"
          showArrow={false}
          collapsible={"disabled"}
          header={
            <Dropdown
              overlay={() => (
                <NewItemMenu itemSection={2} onClick={createNewCard} />
              )}
              placement="topCenter"
              arrow
            >
              <Button
                type="dashed"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={(e) => e.preventDefault()}
              />
            </Dropdown>
          }
          key="addNew"
        ></Panel>
      </Collapse>
    </>
  );
};

export default DevotionEditor;
