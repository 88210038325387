import React, { useState, useEffect } from "react";
import {
  Layout,
  Spin,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Form,
  Select,
  InputNumber,
  Input,
  Typography,
} from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { loadCodes, generateCodes } from "../Utils/SubscriptionCodes";

import "./SubscriptionCodesScreen.css";
import "antd/dist/antd.css";

const { Option } = Select;
const { Title } = Typography;

const SubscriptionCodesScreen = () => {
  const { Content } = Layout;

  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    codeCount: 1,
  });
  const [subscriptionCodes, setSubscriptionCodes] = useState(null);
  const [
    isGenerateCodesModalVisible,
    setIsGenerateCodesModalVisible,
  ] = useState(false);
  const [generatingCodes, setGeneratingCodes] = useState(false);
  const [generatedCodes, setGeneratedCodes] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    loadCodes()
      .then((codes) => {
        setSubscriptionCodes(codes);
        console.log(codes);
      })
      .catch(() => {
        //TODO handle the error here.
      })
      .finally(() => setIsLoading(false));
  }, []);

  const formatCodes = () => {
    let codeString = "";
    generatedCodes.forEach((code) => (codeString = `${codeString}${code}\n`));

    return codeString;
  };

  const tableColumns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => {
        if (a.type < b.type) {
          return -1;
        }
        if (a.type > b.type) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => {
        if (a.type < b.type) {
          return -1;
        }
        if (a.type > b.type) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Reusable",
      dataIndex: "isReusable",
      key: "isReusable",
      render: (isReusable) => <div>{isReusable ? "True" : ""}</div>,
    },
    {
      title: "Redeemed",
      key: "redeemed",
      dataIndex: "redeemed",
      render: (redeemed) =>
        redeemed ? (
          <Tag color={"volcano"}>{"Redeemed"}</Tag>
        ) : (
          <Tag color={"green"}>{"Unused"}</Tag>
        ),
    },

    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
    },

    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {/* <a>Mark used</a>
    //       <a>Delete</a> */}
    //     </Space>
    //   ),
    // },
  ];

  return (
    <>
      <Layout className="subscription-code-screen">
        <Content style={{ padding: "20px 50px" }}>
          <div className="buttons-container">
            <Button
              type="primary"
              onClick={() => setIsGenerateCodesModalVisible(true)}
            >
              Generate Codes
            </Button>
          </div>

          {!!isLoading && (
            <div className="loading_icon__container">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
              />
            </div>
          )}
          {!isLoading && !!subscriptionCodes && (
            <Table dataSource={subscriptionCodes} columns={tableColumns} />
          )}
        </Content>
      </Layout>
      <Modal
        title="Generate Subscription Codes"
        visible={isGenerateCodesModalVisible}
        onCancel={() => setIsGenerateCodesModalVisible(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={generatingCodes}
            onClick={async () => {
              setGeneratingCodes(true);
              const codesGenerated = await generateCodes(
                formValues.codeCount,
                formValues.type,
                formValues.tag
              );
              setGeneratingCodes(false);
              setGeneratedCodes(codesGenerated);
            }}
          >
            Generate
          </Button>,
        ]}
      >
        <Form
          onValuesChange={(newValues) => {
            setFormValues({ ...formValues, ...newValues });
          }}
          initialValues={formValues}
        >
          <Form.Item name="type" label="Subscription type">
            <Select placeholder="Select subscription type">
              <Option value="three_month">Three Month</Option>
              <Option value="six_month">Six Month</Option>
              <Option value="yearly">One Year</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Number to create" name="codeCount">
            <InputNumber min={1} max={100} />
          </Form.Item>
          <Form.Item name="tag" label="Tag">
            <Input />
          </Form.Item>
          {/* //TODO: ADD DATE AND TAG */}
          {!!generatedCodes.length && (
            <>
              <Title level={5}>Generated Codes:</Title>

              <Input.TextArea value={formatCodes()} />
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};
export default SubscriptionCodesScreen;
