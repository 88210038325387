import React, { useEffect, useState } from "react";

import { Button, Form, Input, Select, Upload, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { uploadImageToFirebaseStorage } from "../../Utils/Images";

import { db, storage } from "../../firebase";
import "draft-js/dist/Draft.css";
import "./FormStyles.css";

const WordOfDayCardForm = ({ initialValues, id, dateKey }) => {
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  useEffect(() => {
    setUnsavedChanges(true);
  }, [formValues]);

  const saveContent = () => {
    if (!unsavedChanges) {
      return;
    }
    setSaving(true);
    db.collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .update({
        ...formValues,
      })
      .then(() => {
        setUnsavedChanges(false);
        setSaving(false);
      });
  };

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 4,
      offset: 16,
    },
  };

  const onFormInputsChange = (newValue) => {
    setFormValues({ ...formValues, ...newValue });
  };

  const verifyImageType = (file) => {
    if (file.type === "image/png" || file.type === "image/jpeg") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onValuesChange={onFormInputsChange}
      initialValues={formValues}
    >
      <Form.Item label="Word" name="word">
        <Input placeholder="word" />
      </Form.Item>
      <Form.Item label="Definition" name="definition">
        <Input.TextArea placeholder="definition" />
      </Form.Item>
      <Form.Item>
        <Upload
          name={"file"}
          beforeUpload={verifyImageType}
          customRequest={async (onError, onSuccess, file) => {
            const imageUrl = await uploadImageToFirebaseStorage(
              onError,
              onSuccess,
              file
            );
            setFormValues({ ...formValues, backgroundImageUrl: imageUrl });
          }}
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
        <Image src={formValues.backgroundImageUrl} />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button
          type="primary"
          onClick={saveContent}
          disabled={!unsavedChanges}
          loading={saving}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WordOfDayCardForm;
