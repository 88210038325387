import React, { useEffect, useState } from "react";

import { Button, Form, Input, Upload, Image, Switch } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import IconSelect from "./SharedInputs/IconSelect";

import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  uploadImageToFirebaseStorage,
  verifyImageType,
} from "../../Utils/Images";

import { db } from "../../firebase";
import "draft-js/dist/Draft.css";
import "./FormStyles.css";

const ImageCardForm = ({ initialValues, id, dateKey }) => {
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [form] = Form.useForm();
  const [editorState, setEditorState] = React.useState(() => {
    if (initialValues.description) {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(initialValues.description))
      );
    } else {
      return EditorState.createEmpty();
    }
  });
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  useEffect(() => {
    setUnsavedChanges(true);
  }, [formValues]);

  const saveContent = () => {
    if (!unsavedChanges) {
      return;
    }
    setSaving(true);
    db.collection("Devotions")
      .doc(dateKey)
      .collection("content")
      .doc(id)
      .update({
        ...formValues,
      })
      .then(() => {
        setUnsavedChanges(false);
        setSaving(false);
      });
  };

  const { TextArea } = Input;

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 4,
      offset: 16,
    },
  };

  const onFormInputsChange = (newValue) => {
    setFormValues({ ...formValues, ...newValue });
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onValuesChange={onFormInputsChange}
      initialValues={formValues}
    >
      <Form.Item label="Title" name="title">
        <Input placeholder="title" />
      </Form.Item>
      <Form.Item label="Subtitle" name="subtitle">
        <Input placeholder="subtitle" />
      </Form.Item>
      <IconSelect />
      <Form.Item>
        <Editor
          toolbar={{
            options: ["inline", "list", "history"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          editorState={editorState}
          toolbarClassName="todo"
          wrapperClassName="editor-wrapper"
          editorClassName="todo"
          onEditorStateChange={setEditorState}
          onChange={(text) =>
            onFormInputsChange({ description: JSON.stringify(text) })
          }
        />
      </Form.Item>
      <Form.Item label="Attribution" name="attribution">
        <TextArea placeholder="attribution" />
      </Form.Item>

      <Form.Item label="Display with Shadow">
        <Switch
          checked={formValues.autoGenerateBackground}
          onChange={(checked) => {
            onFormInputsChange({ autoGenerateBackground: checked });
          }}
        />
      </Form.Item>

      <Form.Item>
        <Upload
          name={"file"}
          beforeUpload={verifyImageType}
          customRequest={async (onError, onSuccess, file) => {
            const imageUrl = await uploadImageToFirebaseStorage(
              onError,
              onSuccess,
              file
            );
            setFormValues({ ...formValues, imageUrl: imageUrl });
          }}
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
        <Image src={formValues.imageUrl} />
      </Form.Item>
      <Form.Item>
        <Upload
          name={"file"}
          beforeUpload={verifyImageType}
          customRequest={async (onError, onSuccess, file) => {
            const coverImageUrl = await uploadImageToFirebaseStorage(
              onError,
              onSuccess,
              file
            );
            setFormValues({ ...formValues, coverImageUrl: coverImageUrl });
          }}
        >
          <Button icon={<UploadOutlined />}>
            Upload Cover Image (optional)
          </Button>
        </Upload>
        <Image src={formValues.coverImageUrl} />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button
          type="primary"
          onClick={saveContent}
          disabled={!unsavedChanges}
          loading={saving}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ImageCardForm;
