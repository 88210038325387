/* eslint-disable react/prop-types */
import React, { createContext, Component } from 'react'
import { auth } from '../firebase'

class UserProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      userLoading: true,
    }
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((userAuth) => {
      // TODO: CHECK HERE FOR SPECIFIC EMAIL ADDRESSES...
      this.setState({ user: userAuth, userLoading: false })
    })
  }

  render() {
    const { user, userLoading } = this.state
    const { children } = this.props
    return <UserContext.Provider value={{ user, userLoading }}>{children}</UserContext.Provider>
  }
}
export default UserProvider

export const UserContext = createContext({ user: null })
