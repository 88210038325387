import { functions } from "../firebase";

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export async function copyDevotion(documentId, newDocumentId) {
  const copyDevoCloudFunction = functions.httpsCallable("copyDevo");
  await copyDevoCloudFunction({ documentId, newDocumentId }).then((result) => {
    // Read result of the Cloud Function.
    console.log("cloud function result  ", result);
  });
}

export async function moveDevotion(documentId, newDocumentId) {
  const moveDevoCloudFunction = functions.httpsCallable("moveDevo");
  await moveDevoCloudFunction({ documentId, newDocumentId }).then((result) => {
    // Read result of the Cloud Function.
    console.log("cloud function result  ", result);
  });
}

export async function deleteDevotion(documentId) {
  const deleteDevoCloudFunction = functions.httpsCallable("deleteDevo");
  await deleteDevoCloudFunction({ documentId }).then((result) => {
    // Read result of the Cloud Function.
    console.log("cloud function result  ", result);
  });
}
